<template>
    <div class="chapter1">
        <!-- This example requires Tailwind CSS v2.0+ -->
        <div class="bg-gray-800">
            <div class="py-12 sm:py-16 lg:py-24">
                <div class="max-w-7xl mx-auto text-center px-4 sm:px-6 lg:px-8">
                    <div class="max-w-3xl mx-auto space-y-2 lg:max-w-none">
                        <h1>
                        <span class="block text-base text-center text-indigo-400 font-semibold tracking-wide uppercase">Kapitel 1</span>
                        <span class="mt-2 block text-4xl text-center leading-8 font-extrabold tracking-tight text-white sm:text-4xl">Hvad er GDPR & Databeskyttelse</span>
                        </h1>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-justify max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div class="max-w-3xl mx-auto">
                <div class="prose relative pb-16 bg-white overflow-hidden" style="max-width: 100ch!important">
                    <div class="relative px-4 sm:px-6 lg:px-8">
                        <div class="text-lg max-w-prose mx-auto">

                            <h2>
                                <span class="block text-base text-left text-indigo-600 font-semibold tracking-wide uppercase">Sektion 1</span>
                                <span class="mt-2 block text-2xl text-left leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl">Generelt om GDPR & Databeskyttelse</span>
                            </h2>
                            <p class="mt-4 text-l text-gray-700 leading-8">Enhver har ret til beskyttelse af sine personoplysninger, og enhver, der behandler personoplysninger om andre i ikke-privat sammenhæng, er forpligtet til at iagttage disse databeskyttelses rettigheder og til at beskytte personoplysningerne. Disse rettigheder og forpligtelser går samlet under betegnelsen "Databeskyttelsesforordningen".</p>
                            
                            <p class="mt-4 text-l text-gray-700 leading-8">Enhver behandling af andres personoplysninger, der ikke sker i en rent privat sammenhæng, skal ske i overensstemmelse med reglerne på databeskyttelsesområdet. </p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>   
</template>

<script>
// @ is an alias to /src

export default {
  name: 'chapter1',
  components: {
    
  }
}
</script>
